import React from 'react'

class FullscreenWrapper extends React.Component {
  state = {
    height: "100%"
  }
  onResize = this.onResize.bind(this)

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize() {
    const windowHeight = window.innerHeight
    const height = windowHeight > 1200 ?
      windowHeight - 360 :
      windowHeight > 600 ?
        windowHeight - 160 :
        windowHeight > 380 ?
          windowHeight - 80 :
          'auto'

    this.setState({ height: height === 'auto' ? height : `${height}px`})
  }

  render () {
    const { forwardedRef, ...props } = this.props
    const { height } = this.state
    const style = {
      height,
      paddingBottom: height === 'auto' ? '2em' : null
    }

    return (
      <div style={style} ref={forwardedRef} {...props}>
        {this.props.children}
      </div>
    )
  }
}

export default FullscreenWrapper

export const FullscreenWrapperWithRef = React.forwardRef(
  (props, ref) => <FullscreenWrapper forwardedRef={ref} {...props} />
)
