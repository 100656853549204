import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import posed, { PoseGroup } from 'react-pose'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const Blockquote = posed.blockquote({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 }
})

class Quotes extends React.Component {
  static propTypes = {
    quotes: PropTypes.array.isRequired
  }

  state = {
    index: 0
  }

  timer = null

  switchQuote = (rand) => {
    const count = this.props.quotes.length

    if (rand) {
      this.setState({
        index: getRandomInt(0, count - 1)
      })
    } else {
      this.setState(oldState => ({
        index: oldState.index < count - 1 ? oldState.index + 1 : 0
      }))
      this.setTimer()
    }

  }

  setTimer() {
    this.timer = window.setTimeout(this.switchQuote, 10000)
  }

  componentDidMount() {
    this.switchQuote(true)
    this.setTimer()
  }

  componentWillUnmount() {
    window.clearTimeout(this.timer)
  }

  render() {
    return (
      <PoseGroup>
        {this.props.quotes.map((quote, i) => i === this.state.index ?
          <Blockquote key={i}>
            <p>{quote.text}</p>
            <small>{quote.author}</small>
          </Blockquote>
          : false
        )}
      </PoseGroup>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        dataJson {
          quotes {
            author
            text
          }
        }
      }
    `}
    render={data => <Quotes quotes={data.dataJson.quotes} />}
  />
)
