import React, { useCallback } from 'react'
import { Link, graphql, PageProps } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import posed from 'react-pose'
import SplitText from 'react-pose-text'
import GatsbyImage from 'gatsby-image'

import Layout from '../components/layout'
import { ArticlePreviewWithRef } from '../components/articlePreview'
import { FullscreenWrapperWithRef } from '../components/fullscreenWrapper'
import Quotes from '../components/quotes'
import useSessionStorage from '../hooks/useSessionStorage'

const POSE_INIT = 'init'
const POSE_APPEAR = 'appear'

const revealAnimation = {
  [POSE_INIT]: {
    opacity: 0,
    y: -10,
  },
  [POSE_APPEAR]: {
    opacity: 1,
    y: 0,
    delay: ({ i }) => (i === undefined ? 0 : 1200 + i * 250),
  },
}

const charPoses = {
  [POSE_INIT]: {
    opacity: 0,
    y: -10,
  },
  [POSE_APPEAR]: {
    opacity: 1,
    y: 0,
  },
}

const PosedFullscreenWrapper = posed(FullscreenWrapperWithRef)({
  [POSE_INIT]: {},
  [POSE_APPEAR]: {
    staggerChildren: 50,
  },
})

const PosedArticlePreview = posed(ArticlePreviewWithRef)(revealAnimation)

const PosedQuotes = posed.div(revealAnimation)
const ButtonLinkCTA = posed.a(revealAnimation)
const ButtonCTA = posed(Link)(revealAnimation)

const BlogIndex = ({ data, location }: PageProps) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const siteDescription = get(data, 'site.siteMetadata.description')
  const posts = get(data, 'allMarkdownRemark.edges')
  const partners = get(data, 'partners.edges')

  const [animationPlayed, setAnimationPlayed] = useSessionStorage('anim')

  const handlePoseComplete = useCallback(
    (pose) => {
      if (!animationPlayed && pose === POSE_APPEAR) {
        setAnimationPlayed(1)
      }
    },
    [setAnimationPlayed]
  )

  const initialPose = animationPlayed ? POSE_APPEAR : POSE_INIT

  return (
    <Layout location={location}>
      <Helmet title={siteTitle}>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <PosedFullscreenWrapper
        className="home-above-fold"
        initialPose={initialPose}
        pose={POSE_APPEAR}
        onPoseComplete={handlePoseComplete}
      >
        <h1>
          <span className="nowrap">
            <span>
              <SplitText charPoses={charPoses}>T. J. </SplitText>
            </span>
            <SplitText charPoses={charPoses}>Sokol&nbsp;Písek</SplitText>
          </span>
          <small>
            <SplitText charPoses={charPoses}>Odbor všestrannosti</SplitText>
          </small>
        </h1>
        <PosedQuotes>
          <Quotes />
        </PosedQuotes>
        <div className="home-cta">
          <ButtonCTA
            to="/tabory/"
            className="home-cta-button"
          >
            Příměšťáky
          </ButtonCTA>
          <ButtonCTA
            to="/nase-cinnost#kalendar-akci"
            className="home-cta-button"
          >
            Kalendář akcí
          </ButtonCTA>
          <ButtonCTA
            to="/centrum-ov/"
            className="home-cta-button"
          >
            Centrum OV
          </ButtonCTA>
        </div>
      </PosedFullscreenWrapper>

      <section className="home-articles">
        {posts.map(({ node }, index) => {
          const title = get(node, 'frontmatter.title') || node.fields.slug
          return (
            <PosedArticlePreview
              i={index}
              key={node.fields.slug}
              slug={node.fields.slug}
              title={title}
              date={node.frontmatter.date}
              eventDate={node.frontmatter.eventDate}
              eventDateEnd={node.frontmatter.eventDateEnd}
              excerpt={node.excerpt}
              location={location}
              initialPose={initialPose}
              pose={POSE_APPEAR}
            />
          )
        })}
      </section>

      <footer className="home-footer">
        <Link to="/aktuality">Všechny články →</Link>
      </footer>

      <section className="partners">
        <h2 className="h2">T. J. Sokol Písek podporují</h2>
        <div>
          {partners.map(({ node }) => (
            <GatsbyImage key={node.id} fixed={node.childImageSharp.fixed} />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
      filter: { fields: { slug: { regex: "/aktuality/" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "D. M. YYYY")
            eventDate(formatString: "D. M. YYYY")
            eventDateEnd(formatString: "D. M. YYYY")
            title
          }
        }
      }
    }
    partners: allFile(
      filter: { dir: {}, relativeDirectory: { eq: "partners" } }
    ) {
      edges {
        node {
          id
          relativeDirectory
          relativePath
          name
          dir
          extension
          childImageSharp {
            fixed(height: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
